import React from 'react'

import Landing from '../components/Landing'
import Layout from '../components/components/Layout'

const Index = () => (
  <Layout>
    <Landing />
  </Layout>
)

export default Index
